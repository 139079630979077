/*
 * @Author: 许波
 * @Date: 2021-07-31 10:57:49
 * @LastEditTime: 2023-02-05 15:41:22
 */
import { post } from '../utils/index';

// 创建项目，如果录入了账号，则同时创建项目管理员账号
export const $gp_createPro = (params) => post('/project/v3/create', params);

// 获取项目列表
export const $gp_getProList = (params) => post('/project/v3/page', params);

// 修改项目，如果录入了账号，则同时修改项目管理员账号
export const $gp_editProInfo = (params) => post(`/project/v3/modify/${params.projectUuid}`, params);

// 修改项目，如果录入了账号，则同时修改项目管理员账号
export const $gp_editProInfoSub = (params) => post(`/project/v3/modify/${params.subUuid}`, params);

// 获取指定项目信息
export const $gp_getProInfo = (params) => post('/project/v3/detail/{projectUuid}', params);

// 删除项目，如有管理员账号，则同时删除
export const $gp_delProInfo = (params) => post('/project/v3/del/{projectUuid}', params);

// 重置项目密码
export const $gp_resetProPwd = (params) => post('/project/v3/reset-password/{projectUuid}', params);
// 重置项目设备密码
export const $gp_proDevicePassword = (params) => post('/api/group/project/web/v1/reset/devicePassword', params);

// 添加项目子账号
export const $gp_proCreateSub = (params) => post('/project/v3/create/sub/{projectUuid}', params);

// 获取项目子账号
export const $gp_proSubList = (params) => post('/project/v3/list/sub/{projectUuid}', params);

// 删除项目子账号
export const $gp_proSubDel = (params) => post('/project/v3/del/{subUuid}', params);

// 获取项目子账号
export const $gp_proResetSubPwd = (params) => post('/project/v3/reset-password/sub/{subUuid}', params);

// 获取修改项目申请列表
export const $gp_proApplyPage = (params) => post('/project-apply/v3/page', params);

// 获取项目分配票据
export const $gp_proListByCoupon = (params) => post('/project/v3/list/coupon-apply', params);

// // 获取项目 修改项目
export const $gp_proList = (params) => post('/project/v3/list/project-apply', params);

// 申请修改项目
export const $gp_proApplyCreate = (params) => post('/project-apply/v3/create', params);

// 修改项目申请详情
export const $gp_proApplyDetail = (params) => post('/project-apply/v3/detail/{applyUuid}', params);

// 审批修改项目申请
export const $gp_proApply = (params) => post('/project-apply/v3/approve/{applyUuid}', params);

/** PRO */
// 获取当前项目信息
export const $gp_getCurProDetail = (params) => post('/api/project/project/web/v1/detail', params);
